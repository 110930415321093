@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sarpanch:wght@400;500;600;700;800;900&display=swap');

@layer utilities {
  @variants responsive {
    /*  map options  */

   /*  map options  */
    .button-radius {
      border-radius: 15px;
    }

    .test {
      border: 1px solid red;
    }

    .app-max-width {
      max-width: 1440px;
    }

    .app-font {
      font-family: 'Sarpanch', sans-serif;
    }

    .morphic-button {
      border-radius: 15px;
      background: #f7f7f7;
      box-shadow:  7px 7px 19px #a1a1a1,
      -7px -7px 19px #ffffff;
    }

    .morphic-attribute-button {
      border-radius: 15px;
background: #f7f7f7;
box-shadow:  5px 5px 17px #868686,
             -5px -5px 17px #ffffff;
    }
    .morphic-attribute-button-pressed {
      border-radius: 15px;
background: #f7f7f7;
box-shadow: inset 5px 5px 4px #cacaca,
            inset -5px -5px 4px #f6f6f6;
    }

  }
}